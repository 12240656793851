import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Sparkles } from "lucide-react";

type CVSection = {
    title: string;
    original: string;
    enhanced: string;
};

const CVSections: CVSection[] = [
    {
        title: "Professional Summary",
        original:
            "Marketing manager with 5 years of experience in digital marketing and campaign management.",
        enhanced:
            "Results-driven marketing manager with 5+ years of experience, specializing in data-driven digital marketing strategies and multi-channel campaign management. Proven track record of increasing brand awareness and driving ROI through innovative marketing initiatives.",
    },
    {
        title: "Experience",
        original:
            "Marketing Manager at TechStart Inc.\n• Managed marketing campaigns\n• Worked with social media platforms\n• Analyzed marketing data",
        enhanced:
            "Senior Marketing Manager at TechStart Inc.\n• Spearheaded omni-channel marketing campaigns, resulting in a 40% increase in customer engagement and a 25% boost in conversion rates\n• Orchestrated social media strategy across platforms, growing follower base by 150% and increasing organic reach by 200%\n• Leveraged advanced analytics to optimize marketing ROI, resulting in a 30% reduction in customer acquisition costs",
    },
    {
        title: "Skills",
        original: "Digital marketing, social media, data analysis",
        enhanced:
            "Digital marketing strategy, Marketing automation (HubSpot, Marketo), SEO/SEM, Content marketing, Social media marketing, Data analysis & visualization (Google Analytics, Tableau), A/B testing, Brand management, Marketing ROI optimization",
    },
    {
        title: "Achievements",
        original: "Increased website traffic",
        enhanced:
            "• Led a rebranding initiative that increased brand recall by 45% and website traffic by 75%\n• Implemented a customer segmentation strategy that improved email open rates by 30% and click-through rates by 25%\n• Launched an influencer marketing program that generated $500K in additional revenue within the first quarter",
    },
];

export default function MarketingManagerCVComparison() {
    const [isEnhanced, setIsEnhanced] = useState(false);

    const HighlightedText = ({ text, isEnhanced }: { text: string; isEnhanced: boolean }) => {
        return (
            <>
                {text.split("\n").map((line, lineIndex) => (
                    <p key={lineIndex} className="mb-1">
                        {line.split(" ").map((word, wordIndex) => {
                            const isOriginal = CVSections.some((section) =>
                                section.original.toLowerCase().includes(word.toLowerCase()),
                            );
                            const isNew =
                                !isOriginal &&
                                CVSections.some((section) =>
                                    section.enhanced.toLowerCase().includes(word.toLowerCase()),
                                );

                            return (
                                <span
                                    key={wordIndex}
                                    className={`
                    ${isNew && isEnhanced ? "font-bold text-blue-600 dark:text-blue-400" : ""}
                    ${!isNew && !isOriginal && !isEnhanced ? "line-through text-muted-foreground" : ""}
                  `}
                                >
                                    {word}{" "}
                                </span>
                            );
                        })}
                    </p>
                ))}
            </>
        );
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-6 space-y-8 border-4 border-gray-200 dark:border-gray-700 rounded-xl shadow-lg">
            <div className="flex items-center justify-center space-x-2">
                <Switch id="CV-toggle" checked={isEnhanced} onCheckedChange={setIsEnhanced} />
                <Label
                    htmlFor="CV-toggle"
                    className="text-lg font-semibold flex items-center gap-2"
                >
                    {isEnhanced ? (
                        <>
                            <Sparkles className="w-5 h-5 text-pink-600" />
                            <span className="bg-gradient-to-r from-pink-600 via-purple-700 to-blue-700 text-transparent bg-clip-text">
                                AI-Enhanced CV
                            </span>
                        </>
                    ) : (
                        "Original CV"
                    )}
                </Label>
            </div>

            <div className="space-y-6">
                {CVSections.map((section, index) => (
                    <Card key={index}>
                        <CardHeader>
                            <CardTitle>{section.title}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div
                                className={`relative overflow-hidden transition-all duration-500 ${isEnhanced ? "bg-gradient-to-br from-pink-600/5 via-purple-700/5 to-blue-700/5" : ""}`}
                            >
                                <HighlightedText
                                    text={isEnhanced ? section.enhanced : section.original}
                                    isEnhanced={isEnhanced}
                                />
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
}
