import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Award, Bot, Target } from "lucide-react";
import { useState } from "react";

export default function TipsSection() {
    const [activeTab, setActiveTab] = useState("achievements");

    const tabs = [
        { value: "achievements", label: "Achievements", icon: Award },
        { value: "ats", label: "ATS Tips", icon: Bot },
        { value: "industry", label: "Industry-Specific", icon: Target },
    ];

    return (
        <section className="w-full">
            <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    <div className="space-y-2">
                        <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                            Tailored Tips: Get Insider Advice for a Standout CV
                        </h2>
                        <p className="mx-auto max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            In this section, we're sharing expert guidance to make your CV
                            shine. From crafting powerful achievements to navigating complex ATS
                            filters, our Tailored Tips provide you with the know-how to improve
                            every application.
                        </p>
                    </div>
                </div>
                <div className="mx-auto max-w-5xl mt-12">
                    <div className="md:hidden mb-6">
                        <Select onValueChange={setActiveTab} defaultValue={activeTab}>
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select a topic" />
                            </SelectTrigger>
                            <SelectContent>
                                {tabs.map((tab) => (
                                    <SelectItem key={tab.value} value={tab.value}>
                                        <div className="flex items-center">
                                            <tab.icon className="mr-2 h-4 w-4" />
                                            {tab.label}
                                        </div>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-8">
                        <TabsList className="hidden md:grid w-full grid-cols-3 gap-4">
                            {tabs.map((tab) => (
                                <TabsTrigger
                                    key={tab.value}
                                    value={tab.value}
                                    className="data-[state=active]:bg-gradient-to-br data-[state=active]:from-pink-600 data-[state=active]:via-purple-700 data-[state=active]:to-blue-700 data-[state=active]:text-white"
                                >
                                    <tab.icon className="mr-2 h-4 w-4" />
                                    {tab.label}
                                </TabsTrigger>
                            ))}
                        </TabsList>
                        <TabsContent value="achievements">
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        How to Frame Your Achievements for Maximum Impact
                                    </CardTitle>
                                    <CardDescription>
                                        When describing your achievements, focus on results. Use
                                        metrics to quantify your impact, and start each bullet with
                                        a strong action verb.
                                    </CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <div className="grid gap-4 md:grid-cols-2">
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                Before:
                                            </h4>
                                            <p className="rounded-lg border p-4">
                                                "Worked on marketing campaigns to increase brand
                                                awareness."
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                After:
                                            </h4>
                                            <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                "Boosted brand awareness by 40% through targeted
                                                social media campaigns, resulting in a 25% increase
                                                in customer engagement."
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid gap-4 md:grid-cols-2 mt-4">
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                Before:
                                            </h4>
                                            <p className="rounded-lg border p-4">
                                                "Managed a team and improved productivity."
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                After:
                                            </h4>
                                            <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                "Led a 10-person team to increase productivity by
                                                35% through implementation of agile methodologies
                                                and weekly performance reviews."
                                            </p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </TabsContent>
                        <TabsContent value="ats">
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        Navigating Applicant Tracking Systems (ATS)
                                    </CardTitle>
                                    <CardDescription>
                                        Use clear, straightforward language and avoid
                                        over-complicated formatting. Focus on including keywords
                                        from the job description to improve compatibility with ATS.
                                    </CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <div className="grid gap-4 md:grid-cols-2">
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                Before:
                                            </h4>
                                            <p className="rounded-lg border p-4">
                                                "Extensive experience in client solutions and
                                                customer satisfaction."
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                After:
                                            </h4>
                                            <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                "Customer Solutions Expert: 5+ years of experience
                                                in client support, relationship management, and
                                                satisfaction improvement."
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid gap-4 md:grid-cols-2 mt-4">
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                Before:
                                            </h4>
                                            <p className="rounded-lg border p-4">
                                                "Skilled in various programming languages."
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-muted-foreground">
                                                After:
                                            </h4>
                                            <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                "Proficient in Python, Java, and JavaScript with 5+
                                                years of experience in developing scalable web
                                                applications."
                                            </p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </TabsContent>
                        <TabsContent value="industry">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Industry-Specific Phrasing</CardTitle>
                                    <CardDescription>
                                        Customize your CV language to resonate with your target
                                        industry. For example, in tech, emphasize skills like
                                        "optimization" and "efficiency"; in marketing, highlight
                                        "brand growth" and "campaign success."
                                    </CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-8">
                                    <div className="space-y-4">
                                        <h4 className="font-semibold">Tech Industry</h4>
                                        <div className="grid gap-4 md:grid-cols-2">
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    Before:
                                                </h4>
                                                <p className="rounded-lg border p-4">
                                                    "Responsible for software upgrades and team
                                                    collaboration."
                                                </p>
                                            </div>
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    After:
                                                </h4>
                                                <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                    "Led optimization of system processes, enhancing
                                                    efficiency by 30% through collaborative software
                                                    upgrades."
                                                </p>
                                            </div>
                                        </div>
                                        <div className="grid gap-4 md:grid-cols-2 mt-4">
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    Before:
                                                </h4>
                                                <p className="rounded-lg border p-4">
                                                    "Worked on database management."
                                                </p>
                                            </div>
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    After:
                                                </h4>
                                                <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                    "Optimized database performance, reducing query
                                                    response times by 50% and improving overall
                                                    system efficiency."
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <h4 className="font-semibold">Marketing Industry</h4>
                                        <div className="grid gap-4 md:grid-cols-2">
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    Before:
                                                </h4>
                                                <p className="rounded-lg border p-4">
                                                    "Helped with brand projects and social media."
                                                </p>
                                            </div>
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    After:
                                                </h4>
                                                <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                    "Drove brand growth by creating and executing
                                                    high-impact social media campaigns, resulting in
                                                    a 20% follower increase."
                                                </p>
                                            </div>
                                        </div>
                                        <div className="grid gap-4 md:grid-cols-2 mt-4">
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    Before:
                                                </h4>
                                                <p className="rounded-lg border p-4">
                                                    "Created content for various platforms."
                                                </p>
                                            </div>
                                            <div className="space-y-2">
                                                <h4 className="font-medium text-muted-foreground">
                                                    After:
                                                </h4>
                                                <p className="rounded-lg border border-green-200 bg-green-50 p-4 dark:bg-green-950/10 dark:border-green-950/20">
                                                    "Developed and executed a multi-channel content
                                                    strategy, increasing organic traffic by 75% and
                                                    boosting conversion rates by 25% across all
                                                    platforms."
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </section>
    );
}
