import { Button } from "~/components/ui/button";
import { Rocket } from "lucide-react";
import { useEffect, useState } from "react";
import { cn } from "~/lib/utils";
import { Link } from "@remix-run/react";

export default function FloatingCTA() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div
            className={cn(
                "right-4 bottom-4 z-50 transition-opacity delay-150 duration-600 ease-in-out",
                isVisible ? "fixed opacity-100" : "opacity-0",
            )}
        >
            <Button
                className="transform rounded-full bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 px-4 py-2 font-bold text-white shadow-lg transition-all duration-300 ease-in-out hover:scale-105 hover:from-pink-700 hover:via-purple-800 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 plausible-event-name=GetStarted"
                asChild
            >
                <Link to="/auth/signup">
                    <Rocket className="mr-2 h-5 w-5" />
                    Get Started Now
                </Link>
            </Button>
        </div>
    );
}
