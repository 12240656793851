import { Sparkles, FileText, Target, Pencil } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import MarketingManagerCVComparison from "./marketing-manager-cv-comparison";

export default function SeeTheDifferenceSection() {
    return (
        <section className="w-full space-y-20">
            {/* Hero Section */}
            <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center space-y-4 text-center">
                    <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                        Transform Your CV with{" "}
                        <span className="bg-gradient-to-r from-pink-600 via-purple-700 to-blue-700 text-transparent bg-clip-text">
                            Intelligent AI
                        </span>{" "}
                        – See the Difference
                    </h1>
                    <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                        Watch how TailoredCV.ai enhances each part of your CV to make you stand
                        out for every job application.
                    </p>
                </div>
            </div>

            {/* Main Content Section */}
            <div className="container px-4 md:px-6">
                <div className="flex flex-col lg:flex-row gap-12">
                    {/* Left Column - Text Content */}
                    <div className="lg:w-1/2 space-y-8">
                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <FileText className="w-12 h-12 mb-4 text-pink-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Experience Rewritten for Impact
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    TailoredCV.ai analyzes your job history and highlights relevant
                                    accomplishments. Instead of generic descriptions, it rewrites
                                    each role to showcase the skills and achievements that matter
                                    most to the position you're applying for.
                                </p>
                            </CardContent>
                        </Card>

                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <Target className="w-12 h-12 mb-4 text-purple-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Skills that Speak to the Role
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    Our AI doesn't just copy keywords. It intelligently prioritizes
                                    your strongest skills, ensuring they align with the job
                                    description and convey real value to recruiters.
                                </p>
                            </CardContent>
                        </Card>

                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <Sparkles className="w-12 h-12 mb-4 text-blue-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Personalized to Stand Out
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    From top to bottom, TailoredCV.ai refines every section, not
                                    just adding keywords but also improving flow and readability.
                                    It's a complete CV makeover designed to make an impression.
                                </p>
                            </CardContent>
                        </Card>

                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <Pencil className="w-12 h-12 mb-4 text-pink-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Cover Letters Tailored Instantly
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    TailoredCV.ai even crafts a customized cover letter that pairs
                                    perfectly with your optimized CV, making it easier than ever
                                    to apply confidently.
                                </p>
                            </CardContent>
                        </Card>
                    </div>

                    {/* Right Column - CV Comparison */}
                    <div className="lg:w-1/2">
                        <div className="sticky top-8">
                            <MarketingManagerCVComparison />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
