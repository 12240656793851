import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { MetaFunction } from "@remix-run/cloudflare";
import { Link } from "@remix-run/react";
import { ArrowRight } from "lucide-react";
import { ReactNode } from "react";
import { FAQComponent } from "~/components/website/faq";
import Features from "~/components/website/features";
import FloatingCTA from "~/components/website/floating-cta";
import HeroSection from "~/components/website/hero";
import HowItWorksSection from "~/components/website/how-it-works";
import PricingSection from "~/components/website/pricing";
import SeeTheDifferenceSection from "~/components/website/see-the-difference";
import TestimonialsSection from "~/components/website/testimonials";
import TipsSection from "~/components/website/tips";

export const meta: MetaFunction = () => {
    return [
        { title: "AI-Powered CV Tailoring for Every Job | TailoredCV.ai" },
        {
            name: "description",
            content:
                "Boost your interview chances with AI-tailored CVs. TailoredCV.ai customizes your resume for each job posting, showcasing your true potential. Sign up now and enhance your job applications effortlessly.",
        },
        {
            name: "keywords",
            content:
                "AI CV tailoring, custom CV, job application, CV builder, resume customization, tailored resume, job interview CV, AI-powered resume, CV templates, personalized CV, AI job matching, tailored CV tool",
        },
        {
            property: "og:url",
            content: "https://tailoredcv.ai/",
        },
        {
            property: "og:type",
            content: "website",
        },
        {
            property: "og:description",
            content:
                "Boost your interview chances with AI-tailored CVs. TailoredCV.ai customizes your resume for each job posting, showcasing your true potential. Sign up now and enhance your job applications effortlessly.",
        },
        {
            property: "og:image",
            content: "https://tailoredcv.ai/og-image.png",
        },
        {
            property: "og:title",
            content: "AI-Powered CV Tailoring for Every Job | TailoredCV.ai",
        },
        {
            property: "og:site_name",
            content: "TailoredCV.ai",
        },
        {
            name: "twitter:card",
            content: "summary_large_image",
        },
        {
            property: "twitter:domain",
            content: "tailoredcv.ai",
        },
        {
            property: "twitter:url",
            content: "https://tailoredcv.ai",
        },
        {
            name: "twitter:title",
            content: "AI-Powered CV Tailoring for Every Job | TailoredCV.ai",
        },
        {
            name: "twitter:description",
            content:
                "Enhance your job search with AI-tailored CVs that align with job postings. Increase your interview chances with TailoredCV.ai.",
        },
        {
            name: "twitter:image",
            content: "https://tailoredcv.ai/og-image.png",
        },
        {
            name: "image",
            content: "https://tailoredcv.ai/og-image.png",
        },
    ];
};

const AlternatingBackground = ({ children, index }: { children: ReactNode; index: number }) => (
    <div className={`w-full py-12 ${index % 2 === 0 ? "bg-white" : "bg-purple-50"}`}>
        {children}
    </div>
);

const SECTIONS = [
    <HeroSection key={0} />,
    <Features key={1} />,
    <HowItWorksSection key={2} />,
    <SeeTheDifferenceSection key={3} />,
    <TipsSection key={4} />,
    <TestimonialsSection key={5} />,
    <PricingSection key={6} />,
    <FAQComponent key={7} />,
];

export default function Homepage() {
    return (
        <main className="flex-1">
            {SECTIONS.map((section, index) => (
                <AlternatingBackground index={index} key={section.key}>
                    {section}
                </AlternatingBackground>
            ))}
            <section
                id="cta"
                className="w-full bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 py-12 md:py-24 lg:py-32"
            >
                <div className="container px-4 md:px-6">
                    <div className="flex flex-col items-center space-y-4 text-center">
                        <div className="space-y-2">
                            <h2 className="font-bold text-3xl text-white tracking-tighter sm:text-4xl md:text-5xl">
                                Ready to Boost Your Career?
                            </h2>
                            <p className="animation-delay-200 mx-auto max-w-[600px] text-purple-100 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                Join the first wave of professionals using tailoredcv.ai to land
                                their dream jobs.
                            </p>
                        </div>
                        <div className="animation-delay-400 w-full max-w-sm space-y-2">
                            <form className="flex space-x-2" action="/auth/signup">
                                <Input
                                    className="max-w-lg flex-1 bg-white text-purple-900 placeholder-purple-300"
                                    placeholder="Enter your email"
                                    type="email"
                                    name="email"
                                />
                                <Button
                                    type="submit"
                                    className="bg-white text-purple-600 transition-colors duration-200 hover:bg-purple-50 plausible-event-name=GetStarted"
                                >
                                    Get Started
                                </Button>
                            </form>
                            <p className="text-purple-100 text-xs">
                                Limited time offer. Lock in your lifetime discount now.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FloatingCTA />
        </main>
    );
}
