import { useState, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { marked } from "marked";

const faqItems = [
    {
        question: "Why do I need a tailored CV?",
        answer: "A tailored CV is crucial in today's competitive job market. When you customize your CV for each job application, you're **more likely to catch the attention of hiring managers and applicant tracking systems (ATS)**. By highlighting the skills and experiences most relevant to each position, you **significantly increase your chances of landing an interview**. It shows that you've put in the effort to understand the role and demonstrates how you're the perfect fit.",
    },
    {
        question: "How does tailoredcv.ai help me create tailored CVs quickly?",
        answer: "At tailoredcv.ai, we've developed an **AI-powered system that analyzes job descriptions and your existing CV to create perfectly tailored versions in minutes**. Our AI understands the key requirements of each job posting and restructures your CV to emphasize your most relevant skills and experiences. **This process, which could take hours if done manually, is completed in just a few clicks**. This speed allows you to apply to multiple jobs in the time it would typically take to tailor one CV manually.",
    },
    {
        question: "Can tailoredcv.ai help me apply to more jobs at once?",
        answer: "By automating the CV tailoring process, we enable you to **apply to significantly more jobs in less time**. Instead of spending hours tweaking your CV for each application, you can use our AI to generate multiple tailored CVs quickly. This efficiency allows you to **cast a wider net in your job search, increasing your chances of finding and securing the perfect position**. Plus, each application you submit will be of high quality, tailored specifically to that job.",
    },
    {
        question: "What makes tailoredcv.ai different from other CV builders?",
        answer: "Unlike generic CV builders, we use **advanced AI to truly understand both your CV and the job descriptions**. Our system doesn't just swap out keywords; it intelligently restructures your CV to best match each job. We also offer features like **CV performance analytics and multiple CV templates**. Plus, our AI is constantly learning and improving, ensuring you always have access to the most effective CV tailoring technology.",
    },
    {
        question: "How do I get started with tailoredcv.ai?",
        answer: "Getting started is easy! First, you'll create an account and **enter your CV information into our intuitive CV builder**. Our AI will analyze this data and store it securely as your source of truth. Then, when you're ready to apply for a job, simply paste the job description into our platform. **Our AI will quickly generate a tailored version of your CV**. You can review and fine-tune it if needed, then download and send it off with your application. It's that simple!",
    },
    {
        question: "How does tailoredcv.ai generate CV options?",
        answer: `We offer three levels of customization for each section of your CV:

1. **Conservative:** This option uses synonyms and mild tone changes to subtly tailor your CV while keeping most of your original content intact.
2. **Balanced:** This option is more creative, adding relevant points based on your experiences to better match the job description.
3. **Creative:** This option gives our AI the most freedom to restructure and enhance your CV, potentially adding new relevant skills or experiences based on your profile.

You can **mix and match these options for different sections of your CV, or make manual tweaks** as you see fit. This flexibility ensures you have full control over your final CV while benefiting from our AI's insights.`,
    },
    {
        question: "Is my information secure with tailoredcv.ai?",
        answer: "We take your privacy and security very seriously. **Your CV data is stored securely on our encrypted servers, and we never share your personal information**. Our AI uses your data solely for the purpose of tailoring your CV. We comply with all relevant data protection regulations to ensure your information is always safe with us.",
    },
    {
        question: "What if I need help using tailoredcv.ai?",
        answer: "We're here to support you every step of the way! We offer **comprehensive guides and tutorials within the platform** to help you make the most of our features. If you need additional assistance, our **dedicated support team is always ready to help**. You can reach out to us via email or through our in-app chat support, and we'll be happy to address any questions or concerns you may have.",
    },
    {
        question: "How much does tailoredcv.ai cost?",
        answer: `We offer a Pro Plan with two flexible pricing options:

1. **$10 per month** when paid annually (that's just $120 for a full year!)
2. **$12 per month** when paid monthly

Both options give you full access to all our features, including AI-powered CV customization, unlimited CV tailoring, CV performance analytics, and multiple CV templates.

To put this in perspective:
- Our annual plan costs less than a single professional CV writing service (which can range from $200 to $500+).
- It's cheaper than a month's subscription to many job board services.
- At just $0.33 per day (on the annual plan), it's less than the price of a cup of coffee!

We also offer a **7-day no-commitments free trial**. You can try all our features for a week without any charge. (_Note: For abuse prevention, a credit card is required to start the trial, but you won't be charged if you cancel within the trial period._)

Considering the potential impact on your job search and career, tailoredcv.ai offers **exceptional value for money**. Plus, we often run special promotions, so keep an eye out for even better deals!

We believe in providing high-quality tools at an affordable price to help you succeed in your job search. With tailoredcv.ai, you're not just saving money – you're investing in your future.`,
    },
];

export function FAQComponent() {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    useEffect(() => {
        marked.use({
            breaks: true,
            gfm: true,
        });
    }, []);

    return (
        <section className="w-full px-4 py-12" id="faq">
            <div className="max-w-4xl mx-auto">
                <h2 className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text pb-4 text-center font-bold text-4xl text-transparent md:text-5xl mb-8">
                    Frequently Asked Questions
                </h2>
                <div className="space-y-4">
                    {faqItems.map((item, index) => (
                        <div
                            key={item.question}
                            className="border rounded-lg overflow-hidden shadow-sm"
                        >
                            <button
                                className="flex justify-between items-center w-full p-5 text-left bg-white hover:bg-gray-50 transition-colors duration-200 plausible-event-name=FAQ"
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            >
                                <span className="font-semibold text-black">{item.question}</span>
                                <ChevronDown
                                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${openIndex === index ? "rotate-180" : ""}`}
                                />
                            </button>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${openIndex === index ? "max-h-[1200px]" : "max-h-0"}`}
                            >
                                <div
                                    className="p-5 text-gray-600 bg-white prose prose-sm max-w-none"
                                    // biome-ignore lint/security/noDangerouslySetInnerHtml: marked is a safe library, and the content is created by us
                                    dangerouslySetInnerHTML={{ __html: marked(item.answer) }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
